import React, {useState} from 'react';
import Sidebar from './Sidebar'
import Topbar from './Topbar'
import Bottombar from './Bottombar'
import { Outlet } from 'react-router-dom';

const  AppLayout = (props) => {

  const [collapseSidebar, setCollapseSidebar] = useState(false);

const handeCick = () => {
  setCollapseSidebar(!collapseSidebar)
}

  return ( 
      <>
      <div id="wrapper" style={{background: '#f4f5f8'}}>
        <Sidebar/>
        <Topbar/>
        <section id="content-wrapper" className='vh-100' style={{paddingTop:'140px'}}>
              <Outlet />
        </section>
      </div>
     </>
    );
}


export default AppLayout;
