import { useEffect, useState, useContext } from "react"
import axios from 'axios';
import {BASE_URL} from '../config';
import {AuthContext} from '../context/AuthContext';

const useApi =  (props) => {


  const [data, setData] = useState(null)

  const {userInfo} = useContext(AuthContext);

  const  callApi =  () => {

    if (props.method == "get")
    {
        axios.get(BASE_URL + props.url, {
            headers: {Authorization: `Bearer ${userInfo.jwt}`},
          },  
          )
          .then(res => {
            setData(res.data)
            if (props.callback)
            {
                props.callback(res.data);
            }

      
          }, e => {
            console.log(`get data error:  ${e}`);
        });
    }

    if (props.method == "post")
    {

        console.log('api post method call');
        axios.post(
            BASE_URL + props.url,
            props.body,
            {
              headers: {Authorization: `Bearer ${userInfo.jwt}`},
            },
          )
          .then(res => {
            setData(res.data)
            if (props.callback)
            {
                props.callback(res.data);
            }

          })
          .catch(e => {
            console.log(`get data error:  ${e}`);
          });
    }
  };

  useEffect(() => {
    callApi();
  }, []);

  return { data }
};

export default useApi;