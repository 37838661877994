import React, {useContext, useState, useEffect} from 'react';
import { useNavigate, useParams, useLocation, useSearchParams  } from 'react-router-dom'; 
import * as Yup from 'yup';
import {Formik, Form} from 'formik';
import useApi from '../Hooks/useApi'
import Loader from './../Components/Loader'
import FormLabel from '../Components/FormLabel';
import SelectControl from '../Components/SelectControl';
import {AuthContext} from '../context/AuthContext'
import utilities from '../utilities'
import swal from 'sweetalert'


const Application = (props) => {

    const {userInfo} = useContext(AuthContext);


    let { registrationID, yearID, candidateID } = useParams();
    const navigate = useNavigate();
    
    var rID = utilities.decryptData(registrationID);
    var yID = utilities.decryptData(yearID);
    var cID = utilities.decryptData(candidateID);

    //let urlParams = useParams();
    //let location = useLocation();
    //let [urlSearchParams] = useSearchParams();


  const {data} = useApi( {url: '/Exam/GetExamApplicationData?RegistrationID=' + rID + '&YearID=' + yID  + '&CandidateID=' + cID, method: 'get' });


  let formData = {ExamCityID: ''};

  const validationScheme = Yup.object().shape({
    ExamCityID: Yup.string().required("Choose Center")
  })
   

  const sleep = async (milliseconds) => {
    await new Promise(resolve => {
        return setTimeout(resolve, milliseconds)
    });
  };


   const submit = async (values, { setSubmitting }) => {
    let formValues = {
      ...data
    };
    //await sleep(5000);
    formValues.ExamCityID = values.ExamCityID;
    var regID = parseInt(rID);
    if (regID > 0)
    {
      var isDulicateRecord = await utilities.getAsyncData( {url: '/Exam/CheckIfDuplicateExamApplication?RegNo=' + formValues.registrationNo + '&CategoryID=' + formValues.categoryID + '&YearID=' + formValues.yearID , jwt: userInfo.jwt} );
      if (isDulicateRecord == true)
      {
        swal({
          title: "Attention!",
          text: 'The Application has already been submitted. You cannot submit this application twice.',
          icon: "info",
        });
      }
      else
      {
        var challanNumber = await utilities.postData( {url: '/Exam/SaveExamApplicationAndGenerateChallanNo', body: formValues, jwt: userInfo.jwt});
        setSubmitting(false);
        var encryptedChallan = utilities.encryptData(challanNumber.toString());
        navigate('/exam/payment/' + encryptedChallan);  
      }      
    }
    else
    {
      var stdID = await utilities.postData( {url: '/Exam/UpdateExamCenter', body: formValues, jwt: userInfo.jwt});
      if (stdID > 0)
      {
        swal({
          title: "Attention!",
          text: 'Center Change Successfully...',
          icon: "info",
        });
        setSubmitting(false);
        navigate('/exam/admissions');

      }
    }
 

    // utilities.postData( {url: '/Exam/SaveExamApplicationAndGenerateChallanNo', body: formValues, jwt: userInfo.jwt}).then(challanNo => {
    //   setSubmitting(false);
    //   var encryptedChallan = utilities.encryptData(challanNo.toString());
    //   navigate('/exam/payment/' + encryptedChallan);
    // });    
  
  }
   


  return (
    <>
    {!data ? (<Loader />) : (
        <div className="container vh-100">
         <div className="row vh-100 justify-content-center" >
           <div className="" style={{border:'1px solid lightgray', padding: '25px', borderRadius: 10, background: 'white'}}>


           <Formik initialValues={formData} validationSchema={validationScheme} onSubmit={submit} enableReinitialize={true} >
             {formik => (

               <Form style={{padding: '50px', paddingBottom: '10px'}}>

              <div style={{textAlign:'center'}}>
              <h2> Exam Student Information</h2>
                <hr></hr>
                <div className=''>
                  <img src={data.photo} class="rounded-circle" alt={data.studentName}  style={{width: 'auto', height: 'auto', maxWidth:'200px', maxHeight: '200px'}} />
                </div>
                <hr></hr>

              </div>

                <div className=''>
                  <label> <i className='fa fa-barcode'></i> Registration # {data.registrationNo}</label>
                </div>
                <hr></hr>

                <FormLabel label="Exam" data={data.exam}></FormLabel>

                {yearID > 0 ? (
                  <FormLabel label="Year" data={data.year}></FormLabel>) : (<div></div>)
                }

                <FormLabel label="Category" data={data.category}></FormLabel>

                <FormLabel label="Student Name" data={data.studentName}></FormLabel>
                <FormLabel label="Father Name" data={data.fatherName}></FormLabel>
                <FormLabel label="CNIC" data={data.cnic}></FormLabel>
                <FormLabel label="Date of Birth" data={data.dob}></FormLabel>
                <FormLabel label="Contact No." data={data.mobileNo}></FormLabel>
                <FormLabel label="Address" data={data.address}></FormLabel>
                <hr></hr>
                <FormLabel label="Papers" data={data.papers}></FormLabel>
                <hr></hr>
                
                <SelectControl label="Choose Exam Center" name="ExamCityID" className='form-select'>
                  <option value="">--Choose--</option>
                  <option value="18">Attock</option>
                  <option value="27">Bahawalnagar</option>
                  <option value="6">Bahawalpur</option>
                  <option value="24">Bhakkar</option>
                  <option value="20">Chakwal</option>
                  <option value="23">Chiniot</option>
                  <option value="10">DG Khan</option>
                  <option value="4">Faisalabad</option>
                  <option value="8">Gujranwala</option>
                  <option value="28">Gujrat</option>
                  <option value="31">Hafizabad</option>
                  <option value="21">Jhang</option>
                  <option value="19">Jhelum</option>
                  <option value="12">Kasur</option>
                  <option value="16">Khanewal</option>
                  <option value="25">Khushab</option>
                  <option value="1">Lahore</option>
                  <option value="35">Layyah</option>
                  <option value="17">Lodhran</option>
                  <option value="32">Mandi Baha-ud-Din</option>
                  <option value="26">Mianwali</option>
                  <option value="2">Multan</option>
                  <option value="36">Muzaffargarh</option>
                  <option value="14">Nankana Sb</option>
                  <option value="30">Narowal</option>
                  <option value="33">Okara</option>
                  <option value="34">Pakpattan</option>
                  <option value="11">Rahim Yar Khan</option>
                  <option value="37">Rajanpur</option>
                  <option value="3">Rawalpindi</option>
                  <option value="9">Sahiwal</option>
                  <option value="5">Sargodha</option>
                  <option value="13">Sheikhupura</option>
                  <option value="29">Sialkot</option>
                  <option value="22">Toba Tak Singh</option>
                  <option value="15">Vehari</option>
                  <option value="38">AJK (Muzaffarabad)</option>
                  <option value="40">AJK (Mirpur)</option>
                  <option value="7">Gilgit</option>
                  <option value="39">Skurdu</option>                  
                </SelectControl>
                
                <div className="d-grid gap-2 pb-3 pt-4">
                <button className="btn btn-primary" type="submit" disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}>
                   {formik.isSubmitting ? 
                   (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : 
                   (<span >Next</span>)
                    }
                </button>

                </div>
               </Form>
             )}
           </Formik>      
          
           </div>
         </div>
       </div> 

      )
    }       
 
    </>
  );

};

export default Application;


