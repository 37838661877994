import { NavLink } from "react-router-dom";

const  Sidebar = (props) => {    


return ( 

<aside id="sidebar-wrapper">
    <div className="sidebar-brand">
      <img src="/pmf-white-logo.png" style={{width:'90px'}}/>
    </div>
    
    <ul className="sidebar-nav">
      <li >
        <NavLink  to="/exam/students"> <i className="fa fa-user-o"></i>  Students </NavLink>
      </li>
      <li>
        <NavLink   to="/exam/admissions"> <i className="fa fa-address-card-o"></i>  Admissions </NavLink>
      </li>
      <li>
        <NavLink   to="/exam/unpaidFee"> <i className="fa fa-credit-card"></i>  Unpaid Fee </NavLink>
      </li>
      <li>
        <NavLink   to="/exam/print/admissions"> <i className="fa fa-file-o"></i>  Covering Letter </NavLink>
      </li>      
      <li>
        <NavLink   to="/exam/print/rollNoSlips"> <i className="fa fa-file-pdf-o"></i>  Roll No. Slips </NavLink>
      </li>            
    </ul>
  </aside>
    
    )
}


export default Sidebar;
