import React, {useContext, useState, useEffect} from 'react';
import useApi from '../Hooks/useApi'
import Loader from '../Components/Loader'
import utilities from '../utilities'
import useSignalR from '../Hooks/useSignalR'
import {AuthContext} from '../context/AuthContext'
import {BASE_URL} from '../config'

const PrintAdmissions = (props) => {

    const {userInfo} = useContext(AuthContext);
    const [submitting, setSubmitting] = useState(false);

    const PrintCoveringLetter = async() => {
        setSubmitting(true);
        const file = await utilities.downloadPDF( {url: '/Exam/GenerateCoveringLetter', jwt: userInfo.jwt });
        const downloadUrl = window.URL.createObjectURL(file);
        window.open(downloadUrl, '__blank');
        window.URL.revokeObjectURL(downloadUrl);
        setSubmitting(false);
    

        // utilities.downloadFile( {url: '/Exam/GenerateCoveringLetter', jwt: userInfo.jwt}).then(file => {
        //     const downloadUrl = window.URL.createObjectURL(file)
        //     window.open(downloadUrl, '__blank')
        //     window.URL.revokeObjectURL(downloadUrl)
        // });    
    }


return (
    <div className="content">
        <div className="header">
            <label className="title"> Print Exam Applications Covering Letter </label>
        </div>
        <div className="body">
            <div className="animate-bottom">
                <ul>
                    <li>
                        After the close date of Exam Applications submissions,  You will be able to print the admissions covering letter on <span className='fw-bold'> {userInfo.coveringLetterDate} </span> 
                    </li>
                    <li>
                        You are requested to <span className='fw-bold'> signed and stamped it </span>  from the head of your institute as soon as it is avalable for print
                    </li>
                    <li>
                        And <span className='fw-bold'> dispatch the signed letter </span> to the office of Punjab Medical Faculty
                    </li>
                    <li>
                        Roll number slips will not be issued If the letter is not received till <span className='fw-bold'> {userInfo.rollNoSlipsDate} </span> 
                    </li>
                </ul>

                <div>
                     <button className='btn btn-success' type='button' disabled={!userInfo.showCoveringLetter} onClick={PrintCoveringLetter}>
                     {submitting ? 
                      (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : 
                      (<span >Print Admission Covering Letter</span>)                   
                   }

                      
                     </button> 
                </div>
            </div>
        </div>
    </div>


)    

};

export default PrintAdmissions;