

// const prod = {
//     url: {
//      API_URL: ‘https://myapp.herokuapp.com',
//      API_URL_USERS: ‘https://myapp.herokuapp.com/users'}
//    };

//    const dev = {
//     url: {
//      API_URL: ‘http://localhost:3000'
//     }
//    };
   
//    export const config = process.env.NODE_ENV === ‘development’ ? dev : prod;


//export const BASE_URL = 'https://apitestpayment.pmflahore.com';
//export const BASE_URL = 'https://localhost:7105';
//export const BASE_URL = 'https://api.pmflahore.com';
export const BASE_URL = 'https://custom.pmflahore.com';

export const SECRET_KEY = 'AllahMuhammad1@';

