//import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import React, {createContext, useEffect, useState} from 'react';
import {BASE_URL} from '../config';

export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
  const [userInfo, setUserInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [splashLoading, setSplashLoading] = useState(false);
  const [userValidated, setUserValidated] = useState(false);
  const [error, setError] = useState('');

  const smsAuthentication = (userName, password) => {
    setIsLoading(true);
    let url = BASE_URL + '/api/SMSAuthentication?userName=' + userName + '&password=' + password; 
    axios.get(url)
  .then(response => {
    console.log(response);
    setUserValidated(true);
    setIsLoading(false);
  }, e => {
    console.log(`sms authentication error ${e}`);
    setIsLoading(false);
    alert('invalid login details. Check your ID and Password')

  });
  }


  const delay = ms => new Promise(res => setTimeout(res, ms));

  const register = (name, email, password) => {
    setIsLoading(true);

    axios.post(`${BASE_URL}/register`, {
        name,
        email,
        password,
      })
      .then(res => {
        let userInfo = res.data;
        setUserInfo(userInfo);
        //AsyncStorage.setItem('userInfo', JSON.stringify(userInfo));
        setIsLoading(false);
        console.log(userInfo);
      })
      .catch(e => {
        console.log(`register error ${e}`);
        setIsLoading(false);
      });
  };


  const login = async (user, password) => {
    setIsLoading(true);
    let url = BASE_URL + '/Auth/login';
    try
    {
      const res = await axios.post(url, {
        'UserName' : user,
        'Password': password,
        'UserType': 'Institute'
      });
      setUserInfo(res.data);
      setIsLoading(false);  
    }
    catch(e)
    {
      setIsLoading(false);
      if (e.response)
      {
        setError(e.response.data);
      }
      else
      {
        setError(e.message);
      }

    }
    // .then(res => {
    //   setUserInfo(res.data);
    //   setIsLoading(false);
    // })
    // .catch(e => {
    //   setIsLoading(false);
    //   if (e.response)
    //   {
    //     setError(e.response.data);
    //   }
    //   else
    //   {
    //     setError(e.message);
    //   }
    // });

  };

  const logout = () => {
         setIsLoading(true);
         setUserInfo({}); 
         setUserValidated(false);
         setIsLoading(false);
    };







  const isLoggedIn = async () => {
    try {
      setSplashLoading(true);
      await delay(5000);


      //let userInfo = await AsyncStorage.getItem('userInfo');
      //userInfo = JSON.parse(userInfo);

      //if (userInfo) {
      //  setUserInfo(userInfo);
      //}

      setSplashLoading(false);
    } catch (e) {
      setSplashLoading(false);
      console.log(`is logged in error ${e}`);
    }
  };

  useEffect(() => {
    isLoggedIn();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        userValidated,
        userInfo,
        splashLoading,
        error,
        register,
        smsAuthentication,
        login,
        logout,
      }}>
      {children}
    </AuthContext.Provider>
  );
};