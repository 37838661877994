import React, {useContext, useState, useEffect} from 'react';
import useApi from '../Hooks/useApi'
import Loader from '../Components/Loader'
import { useNavigate  } from 'react-router-dom'; 
import utilities from '../utilities'
import {AuthContext} from '../context/AuthContext'

const UnPaidFee = (props) => {

    const {userInfo} = useContext(AuthContext);
const navigate = useNavigate();
const {data} = useApi( {url: '/Exam/GetUnPaidApplications', method: 'get' });

const PayFee =(challanNo) => {
    var encryptedChallan = utilities.encryptData(challanNo.toString());
    navigate('/exam/payment/' + encryptedChallan);
}

  return (
    <>
    {!data ? (<Loader />) : (

    
                            <div className="content">
                                <div className="header">
                                    <label className="title"> UnPaid Applications </label>
                                </div>
                                <div className="body">
                                    <div className="animate-bottom">
                                    <div className="table-responsive"> 
                                            <table className='table table-striped table-hover table-bordered caption-top'>
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Session</th>
                                                        <th>Registration No.</th>
                                                        <th>Student Name</th>
                                                        <th>Father Name</th>
                                                        <th>Exam</th>
                                                        <th>Category</th>
                                                        <th>Challan No</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {data.map((student) => {
                                                                    return (
                                                                        <>
                                                    <tr key={student.challanNo}>
                                                        <td>{student.session}</td>
                                                        <td>{student.registrationNo}</td>
                                                        <td>{student.studentName}</td>
                                                        <td>{student.fatherName}</td>
                                                        <td>{student.exam} {student.yearID > 0 ? ' (' + student.year + ')' : '' } </td>
                                                        <td>{student.category}</td>
                                                        <td>{student.challanNo}</td>
                                                        <td style={{textAlign: 'center'}}> 
                                                        {userInfo.showCoveringLetter ? (null) : (
                                                                                       <button className='btn btn-primary' onClick={() => PayFee(student.challanNo)} > Print Challan </button> 
                                                                                    )
                                                                                }

                                                            
                                                        </td>
                                                    </tr>

                                                                        </>
                                                                    )
                                                                  })
                                                                }
                                                                </tbody>
                                                        </table>
                                                        </div>                                                    


                                    </div>
                                </div>
                            </div>
        )
    }
    </>
  );

};

export default UnPaidFee;