import { useNavigate, useParams } from 'react-router-dom'; 
import useApi from '../Hooks/useApi'
import Loader from './../Components/Loader'
import utilities from '../utilities'
import useSignalR from '../Hooks/useSignalR'


const Confirmation = (props) => {

    let { transaction } = useParams();
    var data = utilities.decryptObject(transaction)
    const navigate = useNavigate();


    const goToStudentsList = () => {
        navigate('/exam/students');
    }

return (
    <div className="content vh-100">
    
        <div className="animate-bottom">

        <div style = {{textAlign: 'center', marginTop: '25px', marginBottom: '25px'}} >
                <span style= {{fontSize: '27px', lineHeight: '41px', fontWeight: '600', letterSpacing: '0.11px', color: '#171725'}} > Applicaion Submitted Successfully.. </span>
        </div>

        <div style = {{textAlign: 'center', marginTop: '5px', marginBottom: '25px'}} >
                <span style= {{fontSize: '22px', lineHeight: '41px', fontWeight: '600', letterSpacing: '0.11px', color: '#bd2c2c'}} > Transaction ID: {data.transactionID} </span>
        </div>



            <div style= {{marginTop: '50px', textAlign: 'center'}} > 
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
            </div>


            <div style = {{textAlign: 'center', marginTop: '25px', marginBottom: '5px'}} >
                <span style= {{fontSize: '18px', lineHeight: '41px', fontWeight: '600', letterSpacing: '0.11px', color: '#171725'}} > Student Name: {data.applicantName} </span>
        </div>

        <div style = {{textAlign: 'center', marginTop: '5px', marginBottom: '5px'}} >
                <span style= {{fontSize: '18px', lineHeight: '41px', fontWeight: '600', letterSpacing: '0.11px', color: '#171725'}} > Exam: {data.exam} </span>
        </div>

        <div style = {{textAlign: 'center', marginTop: '5px', marginBottom: '5px'}} >
                <span style= {{fontSize: '18px', lineHeight: '41px', fontWeight: '600', letterSpacing: '0.11px', color: '#171725'}} > Category: {data.category} </span>
        </div>

            <div style = {{textAlign: 'center', marginTop: '25px'}} >
            <button className="btn btn-primary" type="button" onClick={goToStudentsList} > Apply for Another Student </button>

            </div>
        </div>
    </div>

)    

};

export default Confirmation;