import CryptoJS from 'crypto-js';
import {SECRET_KEY} from './config';
import {BASE_URL} from './config';
import axios from 'axios';

const utilities = {
  
    currencyFormat: function(num){
        return 'Rs. ' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + '/-'
    },
    percentFormat: function(num){
        return num.toFixed(2) + '%'
    }, 
    encryptData: function(data) {
      var wordArray = CryptoJS.enc.Utf8.parse(data);
      var base64 = CryptoJS.enc.Base64.stringify(wordArray);
        return base64;
      },
      decryptData: function(data) {
        var parsedWordArray = CryptoJS.enc.Base64.parse(data);
        var parsedStr = parsedWordArray.toString(CryptoJS.enc.Utf8);
        return parsedStr
      },
      encrypObject: function(data) {
        var wordArray = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
        var base64 = CryptoJS.enc.Base64.stringify(wordArray);
          return base64;
        },
        decryptObject: function(data) {
          var parsedWordArray = CryptoJS.enc.Base64.parse(data);
          var parsedStr = JSON.parse(parsedWordArray.toString(CryptoJS.enc.Utf8));
          return parsedStr
        },      
      getData: function(props)
      {
        return new Promise((resolve, reject) => {
        
            axios.get(BASE_URL + props.url, {
                headers: {Authorization: `Bearer ${props.jwt}`},
              },  
              )
              .then(res => {
                resolve(res.data)
              }, err => {
                reject(null);
                throw err;
            });
          });
      },
      postData: async (props) => { //asycn (props) =>
      {
        try
        {
          const res = await axios.post(BASE_URL + props.url, props.body, {
            headers: {Authorization: `Bearer ${props.jwt}`},
          });
          return res.data;

        }
        catch(e)
        {
          if (e.response)
          {
            alert(e.response.data);
          }
          else
          {
            alert(e.message);
          }
        }
    
        // return new Promise((resolve, reject) => {
        
        //     axios.post(BASE_URL + props.url, props.body, {
        //         headers: {Authorization: `Bearer ${props.jwt}`},
        //       },  
        //       )
        //       .then(res => {
        //         resolve(res.data)
        //       }, err => {
        //         reject(null);
        //         throw err;
        //     });
        //   });
      }},         
      getAsyncData: async (props) => { 
        {
          try
          {
            const res = await axios.get(BASE_URL + props.url, {
              headers: {Authorization: `Bearer ${props.jwt}`},
            });
            return res.data;
          }
          catch(e)
          {
            if (e.response)
            {
              alert(e.response.data);
            }
            else
            {
              alert(e.message);
            }
          }
      
        }},         
        downloadPDF: async (props) => { //asycn (props) =>
          {
            try
            {
              const res = await axios.get(BASE_URL + props.url, {
                responseType: 'blob',
                headers: {Authorization: `Bearer ${props.jwt}`},
              });
              return res.data;
    
            }
            catch(e)
            {
              alert('No. Record Found.');
              // swal({
              //   title: "Attention!",
              //   text: 'No. Record Found.',
              //   icon: "info",
              // });
        
            }
        }},    
      downloadFile: function(props)
      {
        return new Promise((resolve, reject) => {
        
            axios.get(BASE_URL + props.url, {
                responseType: 'blob',
                headers: {Authorization: `Bearer ${props.jwt}`},
              },  
              )
              .then(res => {
                resolve(res.data)
              }, err => {
                reject(null);
                throw err;
            });
          });
      },
}

export default utilities;


