import React, {useContext, useState, useEffect, useReducer} from 'react';
import { useNavigate } from 'react-router-dom'; 
import useApi from '../Hooks/useApi'
import Loader from './../Components/Loader'
import utilities from '../utilities'
//import useSignalR from '../Hooks/useSignalR'
//import {data} from '../config'
import SelectControl from '../Components/SelectControl';
import {AuthContext} from '../context/AuthContext'
import swal from 'sweetalert'


const Students = (props) => {

    const {userInfo} = useContext(AuthContext);
    const navigate = useNavigate();
const[selectedCategory, setSelectedCategory] = useState(0);
const[data, setData] = useState(null);
const[showLoader, setShowLoader]= useState(false);

//const [, forceUpdate] = useReducer(x => x + 1, 0);

//const {connection} = useSignalR();



// if (connection)
// {
//     connection.on("FeePaidNotification", (message) => {
//         alert(message);
//     });    
// }

//const {data} = useApi( {url: '/Exam/DisplayRegularStudentsForExam', method: 'get' });

const {data: categories} = useApi( {url: '/Exam/GetInstituteCategories', method: 'get' });



// useEffect(() => {
//     if (connection)
//     {
//       console.log('SignalR Connection ID Established: ' + connection.connection.connectionId);
//     }
//   }, [connection])

  
//const navigate = useNavigate();

//   useEffect(() => {
//     if (userInfo.jwt)
//     {
//       navigate('/exam/students');
//     }
// }, [userInfo])




// function getObjects(obj, key, val) {
//     var objects = [];
//     for (var i in obj) {
//         if (!obj.hasOwnProperty(i)) continue;
//         if (typeof obj[i] == 'object') {
//             objects = objects.concat(getObjects(obj[i], key, val));
//         } else if (i == key && obj[key] == val) {
//             objects.push(obj);
//         }
//     }
//     return objects;
// }


// function setUserName(studentID, yearID, category, newVal){
    
//     let index = data[yearID]["category"].find(_category => _category.name === category)["students"].findIndex(_student => _student["studentID"] == studentID);
//     if (index == -1)
//     {
//         alert('not exists');
//     }
//     else
//     {
//         data[yearID]["category"].find(_category => _category.name === category)["students"][index]["studentName"]= newVal;
//         forceUpdate();
//     }
// }

const handleCategoryChange = (e) => {
    setShowLoader(true);
    var categoryID = e.target.value;
    setSelectedCategory(categoryID);
    utilities.getData( {url: '/Exam/DisplayRegularStudentsForExam?CategoryID=' + categoryID, jwt: userInfo.jwt}).then(student => {
        setData(student);
        setShowLoader(false);
    })
}

const Apply = (student) => {

    var registrationID = utilities.encryptData(student.registrationID.toString());
    var yearID = utilities.encryptData(student.yearID.toString());
    var candidateID = utilities.encryptData(student.candidateID.toString());



    if (student.yearID == 2)
    {
        utilities.getData( {url: '/Exam/CheckIfAppearedInFirstYear?RegNo=' + student.registrationNo, jwt: userInfo.jwt}).then(isAppeared => {
            if (isAppeared)
            {
                navigate('/exam/application/' + registrationID + '/' + yearID + '/' + candidateID );
            }
            else
            {
                swal({
                    title: "Attention!",
                    text: 'The student has not applied or appeared in the First Year Exam Yet. Please Apply for 1st Year first then you can apply for 2nd Year.',
                    icon: "info",
                  });
            }

        });
        
        
            //const {data: isAppearedInFirstYear} = useApi( {url: '/Exam/CheckIfAppearedInFirstYear', method: 'get', callback: CheckIfAppearedInFirstYear(student.studentID,student.yearID ) });

        // CheckIfAppearedInFirstYear
        //If not appeared in FirstYear
            //Alert (Please apply for 1st Year and then you can apply for second year)
        //else, 

        // var firstYearCategory = getObjects(data[1], 'name', student.category);
        // if (firstYearCategory && firstYearCategory.length > 0)
        // {
        //     var isNotAppearedInFirst = firstYearCategory[0].students.filter(_student => _student.studentID == student.studentID).length > 0;
        //     if (isNotAppearedInFirst)
        //     {
        //         alert('The candidate is not appeared in first year. Please apply for 1st Year and then you can apply for second year');
        //     }
        //     else
        //     {
        //         alert('All Ok! ready to proceed');
        //     }    
        // }
        // else
        // {
        //     alert('category' + student.category + ' not exists in 1st Year');
        // }
    }
    else
    {
        navigate('/exam/application/' + registrationID + '/' + yearID + '/' + candidateID );
        //setUserName(student.studentID, student.yearID, student.category, "IRFAN IBRAHIM");
    }
    
  }

  


  return (
    <>

<div className="content">
    <div className="header">
        <label className="title"> Students List </label>
    </div>

{!categories ? (<Loader />) : (
    <select name="CategoryID" className='form-select' style={{maxWidth: '450px'}} value={selectedCategory } onChange={e => handleCategoryChange(e)}>
        <option value="0">--Select Category--</option>
        {categories?.map((category, key) => {
            return (
                <option value={category.categoryID} key={category.categoryID} >{category.categoryName}</option>
            )})
        }
    </select>
)}

{showLoader ? (<Loader />) : ( <></>
)}
    {!data ? (<></>) : (

    
                                <div className="body">
                                    <div className="animate-bottom">
                                        {data.map((exam, key) => {
                                            return (
                                                <>
                                                    <div style={{textAlign: 'center'}}>
                                                        <h3 key={key}> {exam.yearID == 0 ? 'Old Scheme' : exam.yearID == 1 ? '1s Year' : '2nd Year'} </h3>  
                                                    </div>
                                                    {exam.category.map((category, index) => {
                                                        return (
                                                            <div className="table-responsive" > 
                                                            <table className='table table-striped table-hover table-bordered caption-top'>
                                                                <caption >
                                                                    <span key={index}> {category.name} </span>
                                                                </caption>
                                                                <thead className="table-light">
                                                                    <tr>
                                                                        <th>Session</th>
                                                                        <th>Registration No.</th>
                                                                        <th>Student Name</th>
                                                                        <th>Father Name</th>
                                                                        <th>Category</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                
                                                                <tbody>

                                                                {category.students.map((student) => {
                                                                    return (
                                                                        <>
                                                                            <tr key={student.studentID}>
                                                                                <td>{student.session}</td>
                                                                                <td>{student.registrationNo}</td>
                                                                                <td>{student.studentName}</td>
                                                                                <td>{student.fatherName}</td>
                                                                                <td>{student.category}</td>
                                                                                <td style={{textAlign: 'center'}}> 
                                                                                {userInfo.showCoveringLetter ? (null) : (
                                                                                        <button className='btn btn-primary' onClick={() => Apply(student)} > Apply </button>
                                                                                    )
                                                                                }
                                                                                     
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )
                                                                  })
                                                                }
                                                                </tbody>
                                                        </table>
                                                        </div>
                                                        );

                                                    })
                                                    }
                                                </>
                                            );
                                        })}

                                    </div>


                                    
                                </div>
        )
    }
                                </div>

    </>
  );

};

export default Students;