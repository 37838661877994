import {useContext} from 'react';
import { BrowserRouter as Router, Routes, Route  } from "react-router-dom";

import AppLayout from './Components/AppLayout'

import Login from './Pages/Login'
import Students from './Pages/Students'
import Application from './Pages/Application'
import Payment from './Pages/Payment'
import Confirmation from './Pages/Confirmation'
import Admissions from './Pages/Admissions'
import UnpaidFee from './Pages/UnpaidFee'
import PrintAdmissions from './Pages/PrintAdmissions'
import RollNoSlips from './Pages/RollNoSlips'



import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import '../src/index.css'
import '../src/Style.css'

import {AuthContext} from './context/AuthContext';

function App() {
  const {userInfo, splashLoading} = useContext(AuthContext);
  return (
      <Router>
        <Routes>
        {/* {userInfo.jwt ? ( */}
          <Route element={<AppLayout />}>  
            <Route path="exam/students" element={<Students/>} />
            <Route path="exam/application/:registrationID/:yearID/:candidateID" element={<Application/>} />
            <Route path="exam/payment/:challanNo" element={<Payment/>} />
            <Route path="exam/payment/confirmation/:transaction" element={<Confirmation/>} />
            <Route path="exam/admissions" element={<Admissions/>} />
            <Route path="exam/unpaidFee" element={<UnpaidFee/>} />
            <Route path="exam/print/admissions" element={<PrintAdmissions/>} />
            <Route path="exam/print/rollNoSlips" element={<RollNoSlips/>} />

            <Route path="/exam/slips" element={<Students/>} />
          </Route>
        {/* ):(
          <> */}
            <Route exact  path="/login" element={<Login/>} />
            <Route exact  path="/" element={<Login/>} /> 
          {/* </>
        )
        } */}
        </Routes>
      </Router>
  );
}

export default App;
