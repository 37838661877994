import React, {useContext, useState, useEffect, useReducer} from 'react';
import { useNavigate, useParams } from 'react-router-dom'; 
import jspdf from 'jspdf'
import html2canvas from 'html2canvas'
import useApi from '../Hooks/useApi'
import Loader from './../Components/Loader'
import utilities from '../utilities'
import useSignalR from '../Hooks/useSignalR'


const Payment = (props) => {

    let { challanNo } = useParams();
    var cNo = utilities.decryptData(challanNo);
    const navigate = useNavigate();

    const goToAdmissions = () => {
        navigate('/exam/admissions');
    }

const printChallan = () => {

    const input = document.getElementById('challan');
    html2canvas(input, { scale: 1 })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jspdf('l', 'mm', [297, 210]);
        pdf.addImage(imgData, 'JPEG', 15, 20, 260, 100);
        // pdf.output('dataurlnewwindow');
        pdf.save("Challan.pdf");
      })   
    }

const {connection} = useSignalR();

 if (connection)
 {
     connection.on("TransactionAcknowledgement", (res) => {
        var currentUrl = window.location.pathname;
        if (currentUrl.indexOf("exam/payment/") !== -1)
        {
            var transaction = utilities.encrypObject(res);
            navigate('/exam/payment/confirmation/' + transaction);
        }


     });    
 }

const {data} = useApi( {url: '/Exam/GetChallan?challanNo=' + cNo, method: 'get'  });


  


  return (
    <>
    {!data ? (<Loader />) : (
        <div className="content">
            <div className="header">
                <label className="title"> Fee Payment </label>
            </div>
            
                <div className="animate-bottom">

                    <div className='d-flex flex-row align-items-center justify-content-center mb-5'>
                        <div className='me-3'>
                            <img src='/waiting.gif'></img>
                        </div>
                        <div>
                            <span>Waiting for payment to be complete...</span>
                        </div> 
                    </div>

                    <div id="challan">

                        <div>
                            <h1>Punjab Medical Faculty (PMF Easy Collection)</h1>
                        </div>

                        <div className='row mb-3' style={{border: '1px solid lightgray', backgroundColor: 'white'}}>

                            <div className='col' style={{borderRight: '1px solid lightgray', backgroundColor: 'white'}}>
                                <div style={{textAlign: 'center'}}>
                                        <img src='/logo-onelink.jpg' style={{height: '100px'}}></img>
                                </div>
                                <div>
                                    <h4>Invoice No. {data.oneBillInvoiceNo}</h4>
                                    <p> For online payment (1 Bill) through Jazz Cash/Easy Paisa/Internet Banking/ Mobile Banking/ ATM. </p>
                                </div>
                                                                                    
                            </div>

                            <div className='col'>
                                <div style={{textAlign: 'center'}}>
                                    <img src='/logo-bop.png' style={{height: '100px'}}></img>
                                </div>
                                <div>
                                    <h4>Challan No. {data.formattedChallanNo}</h4>
                                    <p> For Payment in any branch of Bank of Punjab. </p>
                                </div>

                            </div>

                        </div>

                        <div className='d-flex flex-row flex-wrap'>

                            <div className='me-5'>
                            <table className='table table-striped table-hover table-bordered' >
                                    <thead>
                                        <tr>
                                            <th>Fee Type</th>
                                            <th>Paid Untill</th>
                                            <th>Fee Amount</th>
                                            <th>Service Charges</th>
                                            <th>Total Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Regular</td>
                                            <td className='text-success fw-bold '> {data.normalDueDate}</td>
                                            <td> Rs. {data.normalFee}  </td>
                                            <td> Rs. 20  </td>
                                            <td> Rs.  { (data.normalFee + 20)}  </td>
                                        </tr>
                                        <tr>
                                            <td>Late Fee</td>
                                            <td className='text-success fw-bold '> {data.singleDueDate}</td>
                                            <td> Rs. {data.singleLateFee}  </td>
                                            <td> Rs. 20  </td>
                                            <td> Rs. {(data.singleLateFee + 20)} </td>
                                        </tr>
                                        <tr>
                                            <td>Double Late Fee </td>
                                            <td className='text-success fw-bold '> {data.doubleDueDate}</td>
                                            <td> Rs. {data.doubleLateFee}  </td>
                                            <td> Rs. 20  </td>
                                            <td> Rs. {(data.doubleLateFee + 20)} </td>
                                        </tr>                                
                                    </tbody>
                                </table>


                                
                            </div>
                            
                            <div className='flex-fill'>
                            <table className='table table-striped table-hover table-bordered' style={{maxWidth: '500px'}} >
                                    <tr>
                                        <td className='text-cente fw-bold' colSpan={2}>Candidate Details</td>
                                    </tr>
                                    <tr>
                                        <td>Exam</td>
                                        <td>
                                            {data.exam} 
                                            {data.year ?  " (" + (data.year) + ")"  : "" } 
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Registration #</td>
                                        <td>{data.registrationNo}</td>
                                    </tr>

                                    <tr>
                                        <td>Candidate</td>
                                        <td>{data.applicantName} s/d/o {data.fatherName} </td>
                                    </tr>
                                    <tr>
                                        <td>CNIC</td>
                                        <td>{data.cnic}</td>
                                    </tr>
                                    <tr>
                                        <td>Contact No.</td>
                                        <td>{data.mobileNo}</td>
                                    </tr>
                                </table>
                                
                            </div>

                        </div>
                        <div>
                            <ul>
                                <li>Additional Rs. 10/- will be charged if paid online </li>
                            </ul>
                        </div>
                        <div>
                            <p className='fw-bold text-danger'>Note:- Challan cannot be paid after   {data.doubleDueDate}. Unpaid fee will not be issued Roll No. </p>
                        </div>
                    </div>



                    <hr/>

                    <div>
                    <button className='btn btn-success' type='button' onClick={printChallan}> Print Challan </button>
                    <button className='btn btn-primary' style={{marginLeft: '10px'}} onClick={goToAdmissions} type='button' > View Admission(s) </button>
                </div>

                    {/* <h3>Important Instructions regarding Fee Payment</h3>
                    
                    <p>Fee can b paid using:</p>

                    <div className='d-flex flex-row flex-wrap'>
                        
                        <div>
                            <h5>Internet, Mobile Banking Apps, Jazz Cash, Easy Paisa, etc.:</h5>
                            <div>
                                <ol>
                                    <li>Log in to Internet / Mobile Banking</li>
                                    <li>Select the option of GoPb in Bill Payment Options</li>
                                    <li>Enter 17 digits of PSID number and press Ok</li>
                                    <li>Payable Fee Amount along with Applicant Details will be  automatically fetch and display on the screen</li>
                                    <li>Press Pay / Submit / Finish button</li>
                                    <li>Message of successful transaction appears on the screen</li>
                                    <li> <a target="blank" href="https://www.youtube.com/watch?v=N06SsY_zd-o"> Click Here</a>  to see the demo video </li>
                                </ol>
                            </div> 
                        </div>

                        <div>
                            <h5>ATM:</h5>
                            <div>
                                <ol>
                                    <li>Please visit your nearest ATM of 1 Link member bank along with ATM Card</li>
                                    <li>After PIN verification, select the option of GoPb under option of Bill Payment in Main Menu</li>
                                    <li>Enter 17 digits of PSID number and press Ok</li>
                                    <li>Payable Amount will be  automatically fetch and display on the ATM screen</li>
                                    <li>Press Pay / Submit / Finish button</li>
                                    <li>Message of successful transaction appears on the screen</li>
                                    <li> <a target="blank"  href="https://www.youtube.com/watch?v=q0jzDQIlQ78" > Click Here</a>  to see the demo video </li>
                                </ol>
                            </div> 
                        </div>

                        <div>
                            <h5>Any Branch of Bank of Punjab all over the Pakistan:</h5>
                            <div>
                                <ol>
                                    <li>Download the Challan Form from Here</li>
                                    <li>Visit any branch of Bank of Punjab</li>
                                    <li>Present the Challan  From to Customer Service Representative along with Fee</li>
                                    <li>Fee can also be paid on the 1-Link Member Bank brnahces. Click here to check the <a target="blank"  href="https://1link.net.pk/members/">List of 1-Link Member Banks </a> </li>
                                </ol>
                            </div> 
                        </div>


                    </div> */}

                </div>
            
        </div>
        )
    }
    </>
  );

};

export default Payment;