import { useEffect, useState, useContext } from "react"
import axios from 'axios';
import {BASE_URL} from '../config';
import {AuthContext} from '../context/AuthContext';
import * as signalR from "@microsoft/signalr";

const useSignalR =  (props) => {

  const [connection, setConnection] = useState(null);
  const {userInfo} = useContext(AuthContext);



  const  connectSignalR =  () => {
    const conn = new signalR.HubConnectionBuilder()
    .withUrl(`${BASE_URL}/notificationHub`,{
       accessTokenFactory:  () => {
        return userInfo.jwt
       },
     })
    .configureLogging(signalR.LogLevel.Information)
    .withAutomaticReconnect()
    .build();
  

    conn.start().then(() => {

        console.log('SignalR Connected in Hook with Connection ID: ' + conn.connection.connectionId);
        setConnection(conn);
    
    

        })
        .catch((error) => console.log( 'SignalR Connection Error: ' +  error));
  

  };

  useEffect(() => {
    if (userInfo && userInfo.jwt)
    {
        connectSignalR();
    }

    
  }, []);

  return { connection }
};

export default useSignalR;