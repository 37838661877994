import React, {useContext, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import useApi from '../Hooks/useApi'
import Loader from '../Components/Loader'
import utilities from '../utilities'
import {AuthContext} from '../context/AuthContext'

const Admissions = (props) => {
    const navigate = useNavigate();
    const {userInfo} = useContext(AuthContext);
const {data} = useApi( {url: '/Exam/GetCompletedApplications', method: 'get' });

const ChangeExamCenter =(student) => {
    var registrationID = utilities.encryptData(student.registrationID.toString());
    var yearID = utilities.encryptData(student.yearID.toString());
    var candidateID = utilities.encryptData(student.candidateID.toString());
    navigate('/exam/application/' + registrationID + '/' + yearID + '/' + candidateID );
}


  return (
    <>
    {!data ? (<Loader />) : (

    
                            <div className="content">
                                <div className="header">
                                    <label className="title"> Admissions Confirmed </label>
                                </div>
                                <div className="body">
                                    <div className="animate-bottom">
                                        {data.map((exam, key) => {
                                            return (
                                                <>
                                                    <div style={{textAlign: 'center'}}>
                                                        <h3 key={key}> {exam.yearID == 0 ? 'Old Scheme' : exam.yearID == 1 ? '1s Year' : '2nd Year'} </h3>  
                                                    </div>
                                                    {exam.category.map((category, index) => {
                                                        return (
                                                            <div className="table-responsive"> 
                                                            <table className='table table-striped table-hover table-bordered caption-top'>
                                                                <caption >
                                                                    <span key={index}> {category.name} </span>
                                                                </caption>
                                                                <thead className="table-light">
                                                                    <tr>
                                                                        <th>Session</th>
                                                                        <th>Registration No.</th>
                                                                        <th>Student Name</th>
                                                                        <th>Father Name</th>
                                                                        <th>Category</th>
                                                                        <th>Exam Center</th>
                                                                        <th>Action</th>

                                                                    </tr>
                                                                </thead>
                                                                
                                                                <tbody>

                                                                {category.students.map((student) => {
                                                                    return (
                                                                        <>
                                                                            <tr key={student.candidateID}>
                                                                                <td>{student.session}</td>
                                                                                <td>{student.registrationNo}</td>
                                                                                <td>{student.studentName}</td>
                                                                                <td>{student.fatherName}</td>
                                                                                <td>{student.category}</td>
                                                                                <td>{student.examCityName}</td>
                                                                                <td style={{textAlign: 'center'}}> 
                                                                                {userInfo.showCoveringLetter ? (null) : (
                                                                                        <button className='btn btn-primary' onClick={() => ChangeExamCenter(student)} > Change Exam Center </button>
                                                                                    )
                                                                                }

                                                                                     
                                                                                </td>                                                                                
                                                                            </tr>
                                                                        </>
                                                                    )
                                                                  })
                                                                }
                                                                </tbody>
                                                        </table>
                                                        </div>
                                                        );

                                                    })
                                                    }
                                                </>
                                            );
                                        })}

                                    </div>


                                    
                                </div>
                            </div>
        )
    }
    </>
  );

};

export default Admissions;