import React, {useContext, useState, useEffect} from 'react';
import useApi from '../Hooks/useApi'
import Loader from '../Components/Loader'
import utilities from '../utilities'
import useSignalR from '../Hooks/useSignalR'
import {AuthContext} from '../context/AuthContext'

const RollNoSlips = (props) => {

    const {userInfo} = useContext(AuthContext);
    const [submitting, setSubmitting] = useState(false);


    const PrintRollNoSlips = async() => {
        setSubmitting(true);
        const file = await utilities.downloadPDF( {url: '/Exam/GenerateRollNoSlips', jwt: userInfo.jwt });
        const downloadUrl = window.URL.createObjectURL(file);
        window.open(downloadUrl, '__blank');
        window.URL.revokeObjectURL(downloadUrl);
        setSubmitting(false);
    }


return (
    <div className="content">
        <div className="header">
            <label className="title"> Print Roll No. Slips </label>
        </div>
        <div className="body">
            <div className="animate-bottom">
                {/* <p> Roll No. Slips will be issued: </p>
                <ul>
                    <li>
                        After closing date of Exam Applications submissions  
                    </li>
                    <li>
                        After printing, signing & stamping and disptaching of Admissions Covering Letter  to the office of PMF available for print and download on <span className='fw-bold'> {userInfo.coveringLetterDate} </span> 
                    </li>
                    <li>
                        Roll number will be available for Downloads on <span className='fw-bold'> {userInfo.rollNoSlipsDate} </span> 
                    </li>
                </ul> */}

                <div>
                     <button className='btn btn-success' type='button' disabled={!userInfo.showRollNoSlips} onClick={PrintRollNoSlips}>
                     {submitting ? 
                      (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : 
                      (<span >Print Roll No. Slips</span>)                   
                   }

                      
                     </button> 
                </div>

            </div>
        </div>
    </div>


)    

};

export default RollNoSlips;