import React, {useContext, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom'; 
import * as Yup from 'yup';
import {Formik, Form} from 'formik';
import TextboxControl from '../Components/TextboxControl';
import {AuthContext} from '../context/AuthContext';
import useSignalR from '../Hooks/useSignalR'


const Login = (props) => {
  const navigate = useNavigate();
  const {isLoading, login, userInfo, error} = useContext(AuthContext);
  const {connection} = useSignalR({});

  useEffect(() => {
    if (userInfo.jwt)
    {
      navigate('/exam/students');
    }
}, [userInfo])


useEffect(() => {
  if (error)
  {
    alert(error);
  }
}, [error])


//  useEffect(() => {
//    if (connection)
//    {
//      console.log('SignalR Connection ID Established: ' + connection.connection.connectionId);
//    }
//  }, [connection])


  let data = {Username: '', Password: ''};

  const sleep = async (milliseconds) => {
    await new Promise(resolve => {
        return setTimeout(resolve, milliseconds)
    });
  };

  const validationScheme = Yup.object().shape({
    Username: Yup.string().required("User Required"),
    Password: Yup.string().required("Password Required")
  })
   

   const submit = async (values, { setSubmitting }) => {
    //await sleep(5000);
    await login(values.Username, values.Password);
    setSubmitting(false);
   }
   


  return (
    <>
        <div className="container vh-100" style={{maxWidth: '100%', minHeight: '100%'}}>
         <div className="row vh-100 justify-content-center align-items-center">
           <div className="col-sm-7 col-md-6 col-lg-4 col-xl-3" style={{border:'1px solid lightgray', padding: '25px', borderRadius: 10, background: 'white', boxShadow: '0 11px 34px 0 rgba(0,0,0,.2)'}}>

           <div className="divider d-flex align-items-center my-4">
            <div>
              <h3 className="text-center fw-bold mx-3 mb-0">Examination Portal</h3>
            </div>
          </div>

           <div className="text-center">
             <img src="/logo-pmf.png" className="img-fluid" width={90} alt="Punjab Medical Faculty" /> 
          </div>

           <div className="divider d-flex align-items-center my-4">
            <div>
              <h5 className="text-center fw-bold mx-3 mb-0">Login</h5>
            </div>
          </div>

           <Formik initialValues={data} validationSchema={validationScheme} onSubmit={submit} enableReinitialize={true} >
             {formik => (

               <Form>
                 <TextboxControl  label="User Name"  className="form-control form-control-lg mb-4" name="Username"  type="text" placeholder="User Name" />
                 <TextboxControl label="Password" name="Password"  className="form-control form-control-lg" type="password" placeholder="Password"  />                

                <div className="d-grid gap-2 pb-3 pt-4">
                <button className="btn btn-primary" type="submit" disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}>
                   {formik.isSubmitting ? 
                      (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : 
                      (<span >Login</span>)                   
                   }
                </button>

                </div>
               </Form>
             )}
           </Formik>      
          
           </div>
         </div>
       </div> 
 
    </>
  );

};

export default Login;


