import React, {useContext} from 'react';
import {AuthContext} from '../context/AuthContext';
import useSignalR from '../Hooks/useSignalR'
import swal from 'sweetalert' //'@sweetalert/with-react'


const  Topbar = (props) => {    
  const {userInfo} = useContext(AuthContext);
  const {connection} = useSignalR();

  const toggleSidebar = () => {

  }

  if (connection)
  {
      connection.on("TransactionAlert", (res) => {
        if (res.type == "reverse")
        {
          swal({
            title: "Payment Reversed!",
            text: res.message,
            icon: "error",
          });
        }
        else
        {
          var currentUrl = window.location.pathname;
          if (currentUrl.indexOf("exam/payment/") == -1)
          {
            swal({
              title: "Payment Successful!",
              text: res.message,
              icon: "success",
            });
          }
        }



      });    
  }

  
    return (
      <div id="navbar-wrapper">
        <nav className="navbar navbar-inverse fixed-top" >
          <div className="container-fluid">
            <div className="navbar-header">
              <a href="#" className="navbar-brand" id="sidebar-toggle" onClick={toggleSidebar}><i className="fa fa-bars"></i></a>
              
            </div>
                 <ul className="nav-left">
                  <li>
                    <a className="d-f" style={{textDecoration: 'none'}}>
                      <span className='collegeName'>{userInfo.user}</span>
                      
                    </a>
                  </li>              
             </ul>            
             <ul className="nav-right">
               <li>
                 <a href="/" >
                   Logout
                 </a>
               </li>
             </ul>

          </div>
        </nav>
      </div>
    )
  
}

export default Topbar;

