import React from 'react';



const FormLabel = ({ label, data }) => {

    return (
      <>

            <div className='d-flex flex-row flex-wrap mb-3'>
              <div style={{width: '200px'}}>
                <span  class="col-form-label fw-bold">{label}</span>
              </div>                
                <div class="flex-fill">
                    <span>{data}</span>
                </div>
            </div>


      </>
    );
  };

  export default FormLabel;
