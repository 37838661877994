import React from 'react';
import {useField} from 'formik';

const SelectControl = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (

      <div className='d-flex flex-row flex-wrap mb-3'>
        <div style={{width: '200px'}}>
          <span  class="col-form-label fw-bold">{label}</span>
        </div>                
        <div class="flex-fill">
          <select {...field} {...props} />
          {meta.touched && meta.error ? (
            <div className="text-danger">{meta.error}</div>
          ) : null}

        </div>
    </div>




    );
  };

  export default SelectControl;