import React from 'react';
import {useField} from 'formik';
//import { Input, InputGroup } from 'rsuite';
// import {InputText} from 'primereact/inputtext';


const TextboxControl = ({ label, ...props }) => {

  const errorStyle = {
    color: 'red'
  };


    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
      <>

      <div className="form-outline mb-3">
        {/* <label className="form-label" htmlFor={props.id || props.name}>{label}</label> */}
        {/* <InputText className="form-control" {...field} {...props} /> */}
        <input {...field} {...props} />
        {meta.touched && meta.error ? (
          <div style={errorStyle} className="error">{meta.error}</div>
        ) : null}
      </div>
      </>
    );
  };

  export default TextboxControl;
